<template>
    <!-- top -->
        <div>
        <div class="header">
            <!-- logo -->
            <div class="logo"><a href=""><img src="@/assets/LOGO.png" alt=""></a></div>
            <!-- tabs -->
            <div id="smv_tem_3_46" >
                <ul class="w-nav">
                    <li :class="{'activeCss':activeProperty==index}"  v-for="(item , index) in list" :key="index" @click="activeFun(item,index)">
                        {{ item.name }}
                      <span :class="{'sliding': activeProperty==index}" ></span>
                    </li>
                </ul>

               
            </div>
        </div>
        
    </div>
</template>

<script>

    export default {
        components: {

        },
        data(){
            return{
                currentIndex: false,
                list:[
                  {name:"首页" , isboom: false,id: 1},  
                  {name:"关于我们" , isboom: false, id: 2 },  
                  {name:"产品中心" , isboom: false, id: 3},  
                  {name:"解决方案" , isboom: false, id: 4},  
                  {name:"加入索验" , isboom: false, id: 5},  
                ],
                activeVar:0,
                activeProperty: 0,
                
            }
        },
        methods: {
            activeFun(item,index){
                // console.log(index,item);
                 // item 为被选中的元素
				this.activeProperty = index;

                // this.list[index].isboom = !this.list[index].isboom; //点谁谁改变

                // 根据下标跳转
                if(index == 1){
                    this.$router.push('/about').catch(err => {err})
                }else if (index == 2){
                    this.$router.push('/product').catch(err => {err})
                }else if (index == 3){
                    this.$router.push('/solu').catch(err => {err})
                }else if (index == 4){
                    this.$router.push('/joinRu').catch(err => {err})
                }else {
                    this.$router.push('/').catch(err => {err})
                }       
        }
        },
        
    }
    
</script>

<style lang="less" scoped>
    div.current {
        display: block;
        }
    li:hover {
    color: #FF9D43;
    }

.header{    
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100px;
    position: absolute;
    z-index: 999;
    left: 50%; top: 12%;
    transform: translate(-50%, -50%);  
    margin: 0 auto;
    background-color: transparent;
}
.logo img{
    width: 130px;
    height: 47px;
}
#smv_tem_3_46{
    animation:fadenum 2s;
    border-radius: 20px;
}

@keyframes fadenum{
    0%{transform:translateX(-350px);opacity:0.2;}
    50%{opacity:0.5;}
  100%{transform:translateX(0px);opacity:1;}
}
.activeCss {
		color: #FF9D43;
	}
.w-nav{
    height: 70px;
    width: 600px;
    left: 582px;
    top: 27px;
    z-index: 3;
    opacity: 1;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 2px 0 rgba(0,0,0,0);
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 0 rgba(0,0,0,0);
}
.w-nav li{
    position: relative;
    list-style-type:none;
    line-height: 70px;
    text-align: center;
    width: 20%;
    cursor: pointer;
}
.sliding{
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 4px;
    background-image: none;
    background-color: #FF9D43;
}
li{
    color: #000000;
    font-weight: bold;
    font-family: Tahoma;
    font-size: 14px;
}
</style>