<template>
    <div>
        <!-- top -->
        <top />
        <!--  -->
        <router-view></router-view>
    </div>
  </template>
   
  <script>
  import top from './components/TOP/TopNavigation.vue'

  export default {
    components: {
        top,
    },
    data() {
      return {

      }
    },
    created () {
    },
    methods: {
    
    },
  };
  </script>
   
  <style >
*{
    margin: 0;
    padding: 0;
}
  </style>