
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
 

 
// 配置路由
const routes = [
  {
    path: '/',
    component: () => import('../components/homePageIndex.vue'),
    meta: { title: '首页' },
  },
  {
    path: '/about',
    component: () => import('../components/AboutUsMy.vue'),
    meta: {title: '关于我们' }
  },
  {
    path: '/product',
    component: () => import('../components/productCente.vue'),
    meta: {title: '产品中心' }
  },
  {
    path: '/joinRu',
    component: () => import('../components/joinRuSooyan.vue'),
    meta: {title: '加入sooyan' }
  },
  {
    path: '/solu',
    component: () => import('../components/soluTion.vue'),
    meta: {title: '解决方案' }
  },
  {
    path: '/modulPero',
    component: () => import('../components/modulPeroduct.vue'),
    meta: {title: '产品详情' }
   }, 

   {
    path: '/tinfos',
    component: () => import('../components/productCenter/productinfos.vue'),
    meta: {title: '医学智能仿真训练产品' }
   },  
   {
    path: '/tinmos1',
    component: () => import('../components/productCenter/productinmon1.vue'),
    meta: {title: '腹腔镜智能仿真训练系统（虚实结合版）' }
   }, 
   {
    path: '/tinmos2',
    component: () => import('../components/productCenter/productinmon2.vue'),
    meta: {title: '消化内镜智能仿真训练系统' }
   }, 
   {
    path: '/tinmos3',
    component: () => import('../components/productCenter/productinmon3.vue'),
    meta: {title: '腹腔镜智能仿真训练系统（力回馈版）' }
   }, 
   {
    path: '/tinmos4',
    component: () => import('../components/productCenter/productinfos4.vue'),
    meta: {title: '宫腔镜智能仿真训练系统' }
   },
   {
    path: '/license',
    component: () => import('../components/licenSen.vue'),
    meta: {title: '登录授权管理系统' }
   },  

]
 
// 创建路由器
const router = new VueRouter({
    //mode:'history',
    routes
})
 
// 导出路由器
export default router